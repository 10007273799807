import React from 'react';
import './Experience.css';
import './Section.css';
import experienceData from '../data/experienceData.json';

const Experience = () => {
  return (
    <div className="section experience-section" id="experience">
      <h2>Experience</h2>
      {experienceData.map(item => (
        <div key={item.id} className="experience-item">
          <div className="left-section">
            <span className="duration">{item.duration}</span>
          </div>
          <div className="right-section">
            <h3 className="position">{item.position}</h3>
            <p className="company">{item.company}</p>
            <ul className="description">
              {item.description.map((desc, index) => (
                <li key={index}>{desc}</li>
              ))}
            </ul>
            <div className="skills">
              {item.skills.split(',').map((skill, index) => (
                <span key={index} className="skill">{skill}</span>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Experience;
