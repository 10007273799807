import React, { useEffect, useState } from 'react';
// import './Section.css';
import { ArrowUpRightIcon } from '../assets/arrow';
import './Projects.css'

const Projects = () => {
    const [projects, setProjects] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const githubUsername = 'sehgalmaulik';
    const numberOfItems = 6;

    useEffect(() => {
        setIsLoading(true);
        fetch(`https://api.github.com/users/${githubUsername}/repos?per_page=${numberOfItems}`)
            .then((response) => response.json())
            .then((data) => {
                setProjects(data);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data from GitHub:', error);
                setError(error);
                setIsLoading(false);
            });
    }, []);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading projects.</div>;
    }

    return (
        <div id="projects" className="section">
            <h2>Projects</h2>
            <div className="projects-grid">
                {projects.slice(0, numberOfItems).map((project) => (
                    <div key={project.id} className="sub-item">
                        <div className="project-header">
                            <h3>
                                {project.name}
                                <a
                                    href={project.html_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="project-link"
                                >
                                    <span className="project-link-icon">
                                        <ArrowUpRightIcon />
                                    </span>
                                </a>
                            </h3>
                        </div>
                        <p>{project.description}</p>
                    </div>
                ))}
            </div>
            <div className="view-all-container">
                <a href={`https://github.com/${githubUsername}?tab=repositories`} className="archive-link" target="_blank" rel="noopener noreferrer">
                    View archive
                </a>

            </div>


        </div>
    );
};

export default Projects;
