import React from 'react';
import './Section.css';
import './Certs.css';
import certsData from '../data/certsData.json';
import { CREDLY_LINK } from './constants'; 

const Certs = () => {
  return (
    <div className="section" id="certs">
      <h2>Certifications</h2>
      <div className="certs-container">
        {certsData.slice(0, 3).map(cert => (
          <div key={cert.id} className="cert-card sub-item">
            <div className="cert-badge-container">
              {cert.badgeUrl && <img className="cert-badge" src={cert.badgeUrl} alt={`${cert.name} badge`} />}
            </div>
            <div className="cert-text">
              <h3>{cert.name}</h3>
              <p>{cert.issuer} | {cert.year}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="view-all-container">
        <a href={CREDLY_LINK} target="_blank" rel="noopener noreferrer" className="view-all-button">
        view all on Credly
        </a>
      </div>
    </div>
  );
};

export default Certs;
