import React from 'react';
import { FaEnvelope, FaLinkedin, FaGithub, FaTwitter } from 'react-icons/fa';
import './Contact.css';
// import keyIcon from '../assets/key.svg';
import KeyIcon from '../assets/keyicon';
import * as c from './constants';

const Contact = () => {
    return (
        <section id="contact" className="section">
            <h2 className="title">Get in Touch</h2>
            <div className="social-links">
                <a href={c.GITHUB_LINK} target="_blank" rel="noopener noreferrer"title="GitHub"><FaGithub /></a>
                <a href={c.LINKEDIN_LINK} target="_blank" rel="noopener noreferrer"title="LinkedIn"><FaLinkedin /></a>
                <a href={c.TWITTER_LINK} target="_blank" rel="noopener noreferrer" title="Twitter"><FaTwitter /></a>
                <a href={c.PGP_FILE_PATH} download title="Public PGP Key"><KeyIcon /></a>
            </div>
            <div className="contact-content">
                <a href={c.EMAIL_LINK} className="contact-button">Say Hello</a>
            </div>
        </section>
    );
};

export default Contact;
