import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import About from './components/About';
import Experience from './components/Experience';
import Certs from './components/Certs';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Tools from './components/tools'; 

function App() {
  return (
    <div className="App">
      <Navbar />
      <About />
      <Certs />
      <Experience />
      <Projects />
      {/* <Tools /> */}
      <Contact />
    </div>
  );
}

export default App;
