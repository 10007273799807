import React, { useState, useEffect } from 'react';
import './Navbar.css';
import '../App.css';

const Navbar = () => {
    const [visible, setVisible] = useState(true);
    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    // Handle scroll for showing and hiding navbar
    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const visible = prevScrollPos > currentScrollPos || currentScrollPos < 10;
            setVisible(visible);
            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [prevScrollPos]);

    // Handle window resize to switch between text and icons
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <nav className="navbar" style={{ top: visible ? '0' : '-60px' }}>
            <a href="#about">
                <span className={`nav-text ${isMobile ? 'hidden' : ''}`}>About</span>
                <img className={`nav-icon ${isMobile ? '' : 'hidden'}`} src="/path/to/about-icon.png" alt="About" />
            </a>
            <a href="#certs">
                <span className={`nav-text ${isMobile ? 'hidden' : ''}`}>Certs</span>
                <img className={`nav-icon ${isMobile ? '' : 'hidden'}`} src="/path/to/certs-icon.png" alt="Certs" />
            </a>
            <a href="#experience">
                <span className={`nav-text ${isMobile ? 'hidden' : ''}`}>Experience</span>
                <img className={`nav-icon ${isMobile ? '' : 'hidden'}`} src="/path/to/experience-icon.png" alt="Experience" />
            </a>
            <a href="#projects">
                <span className={`nav-text ${isMobile ? 'hidden' : ''}`}>Projects</span>
                <img className={`nav-icon ${isMobile ? '' : 'hidden'}`} src="/path/to/projects-icon.png" alt="Projects" />
            </a>
            <a href="#contact">
                <span className={`nav-text ${isMobile ? 'hidden' : ''}`}>Contact</span>
                <img className={`nav-icon ${isMobile ? '' : 'hidden'}`} src="/path/to/contact-icon.png" alt="Contact" />
            </a>
        </nav>
    );
};

export default Navbar;
